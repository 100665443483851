@use '../config/' as *;
.waf-player,
.player-profile {
    --player-width: 27rem;
    .stats {
        &-item {
            @extend %text-center;
            @extend %white-bg-1;
            @extend %p-4;
            @extend %flex-c-c;
            svg {
                width: 100%;
            }
            &-data {
                flex-direction: column-reverse;
                @extend %flex-c-c;
            }
            &-media {
                width: .1rem;
                @extend %mb-5;
                @extend %flex-column-c-c;
                .image {
                    object-fit: contain;
                    object-position: center;
                    width: 5rem;
                    aspect-ratio: 1/1;
                }
            }
        }
        &-list,
        &-listing {
            @include grid(2);
            svg {
                @extend %w-100;
                @extend %h-100;
            }
            .circle {
                &-inner {
                    stroke: var(--white);
                    stroke-width: 0.5;
                    fill: transparent;
                }
                &-outer {
                    transform: rotate(-90deg);
                    transform-origin: center;
                    stroke-linecap: round;
                    stroke: var(--primary);
                    stroke-width: 2.5;
                }
            }
        }
    }
    .player {
        &-stats {
            &-wrapper {
                @extend %secondary-dark-bg;
                @extend %w-100;
                @extend %p-5-3;
                @extend %white;
            }
            &-head {
                @extend %flex-sb-c;
                @extend %mb-4;
                .title {
                    @extend %d-block;
                    @extend %mb-0;
                    @extend %uppercase;
                }
            }
            &-title {
                @extend %font-12;
                @extend %uppercase;
            }
            &-value {
                @extend %font-26-pb;
            }
        }
    }
    .form-select-box {
        .selected-title {
            @extend %px-4;
        }
        .select-list {
            .btn-text {
                @extend %secondary-dark;
            }
        }
    }
}
@include mq(col-md) {
    .waf-player,
    .player-profile {
        --player-width: 38rem;
        .player-stats {
            &-wrapper {
                position: relative;
                padding: var(--space-6);
            }
            &-value {
                font-size: 4rem;
            }
        }
        .stats {
            &-list,
            &-listing {
                display: flex;
                flex-wrap: wrap;
                gap: var(--space-4);
                @include card-count(5, var(--space-4));
                overflow: visible;
            }
        }
    }
}