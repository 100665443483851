@use './config/' as *;
@forward 'main-scss/';
@forward 'swiper/swiper-bundle';
@forward 'form/common';
@forward 'squad/players-common';
@forward 'matchcenter/';
.load-bg-pattern {
    // html {
    .timeline-container .accordion-body {
        @include bgImg('matchcenter/timeline-bg.png');
    }
    .player-profile {
        &::after {
            @include background('cssimages/players-m.png', top center/cover fixed);
        }
    }
}
// hide timeline for mobile
.timeline-container {
    @extend %d-none;
}
@include mq(col-md) {
    //display timeline for web
    .timeline-container {
        display: block;
    }
    .load-bg-pattern {
        // html {
        .player-profile {
            &::after {
                @include background('cssimages/players.png', top center/cover fixed);
            }
        }
    }
}