@use '../config/' as *;
.tab-container-stats {
    @extend %px-3;
    .match {
        &-teams {
            @include border(1, white, 3, bottom);
            @extend %pb-3;
            @extend %mb-3;
            @extend %flex-c-c;
            @extend %gap-6;
        }
    }
    .team-img {
        width: 4.5rem;
        height: 4.5rem;
        @extend %p-1;
        @extend %white-bg;
        @extend %circle-radius;
    }
    .tab-title {
        @extend %mb-0;
    }
    .label-wrap {
        @extend %flex-sb-fe;
        .label {
            min-width: 4rem;
            @extend %d-block;
            @extend %font-12-pb;
            @extend %white-bg-1;
            @extend %d-block;
            @extend %text-center;
        }
    }
    .statsinfo {
        &-list {
            @include border(1, white, 3, bottom);
            @extend %flex-column;
            @extend %gap-3;
            @extend %pb-3;
            @extend %mb-3;
        }
        &-item {
            box-shadow: 0 0 .4rem .4rem hsl(var(--hsl-black)/.1);
            @extend %text-center;
            @extend %p-3;
            @extend %secondary-light-bg;
            .label {
                @extend %font-12-pm;
                @extend %white-5;
                @extend %mb-1;
            }
            .info {
                @extend %d-inline-block;
                @extend %gap-1;
                &:hover {
                    text-decoration: underline;
                }
            }
            .info,
            .team-anchor {
                @extend %font-16-pb;
                @extend %white;
            }
        }
    }
    .progress .label {
        width: max-content;
        @extend %m-auto;
        @extend %transparent-bg;
        @extend %font-10-pm;
        @extend %accent;
    }
    .progressbar-item {
        @include border(1, white, 3, bottom);
        @extend %relative;
        @extend %text-center;
        @extend %pb-2;
        @extend %mb-2;
    }
    .team-a {
        .label-wrap {
            > .label:first-child {
                @extend %secondary-light-bg;
            }
        }
    }
    .team-b {
        .label-wrap {
            > .label:last-child {
                @extend %secondary-light-bg;
            }
        }
    }
    .circle-inner {
        stroke: hsl(var(--hsl-white)/0.4);
        stroke-width: 3;
    }
    .circle-outer {
        transform: rotate(-90deg);
        transform-origin: center;
        stroke-linecap: round;
        stroke: var(--primary);
        stroke-width: 3;
    }
    .circle-svg {
        width: 100%;
        height: 100%;
        overflow: visible;
    }
    .progress-view {
        width: 5rem;
        height: 5rem;
        &:not(:first-child) {
            .circle-outer {
                stroke: var(--accent);
            }
        }
        &-wrap {
            @extend %my-2;
            @extend %flex-c-c;
            @extend %gap-4;
        }
    }
    .ball-possession {
        .label {
            @extend %font-12-pb;
        }
        .circle-inner {
            stroke: hsl(var(--hsl-accent)/1);
        }
        .progress {
            @extend %m-auto;
            &-view {
                width: 7rem;
                height: 7rem;
            }
        }
        .label-wrap {
            > .label {
                position: absolute;
                top: 25%;
                left: 50%;
                @extend %font-18;
            }
            > :first-child {
                transform: translateX(-12rem);
                @extend %transparent-bg;
            }
            > :last-child {
                transform: translateX(5rem);
                @extend %transparent-bg;
            }
        }
    }
    .passing-accuracy {
        .label {
            @extend %font-12-pb;
        }
        .label-wrap {
            > .label {
                width: 6rem;
                height: 1.2rem;
                position: absolute;
                left: 50%;
                top: 28%;
                @extend %text-center;
                @extend %font-10-pb;
                @extend %transparent-bg;
            }
            > :first-child {
                transform: translateX(-6.4rem);
            }
            > :last-child {
                transform: translateX(0.6rem);
            }
        }
        .progress {
            @extend %m-auto;
        }
    }
}
@include mq(col-md) {
    .tab-container-stats {
        .team-img {
            width: 6rem;
            height: 6rem;
        }
        .ball-possession {
            .progress-view {
                width: 10rem;
                height: 10rem;
                margin-bottom: var(--space-2);
            }
            .label-wrap {
                & > .label {
                    font-size: 2.6rem;
                }
                > :first-child {
                    transform: translateX(-16.5rem);
                }
                > :last-child {
                    transform: translateX(7rem);
                }
            }
        }
        .statsinfo {
            &-list {
                flex-direction: row;
            }
            &-item {
                flex: 1;
                text-align: left;
                .label,
                .info,
                .team-anchor {
                    font-size: 1.4rem;
                }
                .info {
                    justify-content: left;
                    &:hover {
                        text-decoration: none;
                    }
                }
                button.info,
                a.info,
                .team-anchor {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .passing-accuracy {
            .progress-view {
                width: 8rem;
                height: 8rem;
                margin-bottom: var(--space-2);
            }
            .label-wrap {
                & > .label {
                    top: 24%;
                    font-size: 1.6rem;
                    top: 25%;
                }
                > :last-child {
                    transform: translateX(2.3rem);
                }
                > :first-child {
                    transform: translateX(-8rem);
                }
            }
        }
        .label-wrap > .label {
            font-size: 1.8rem;
            width: 8rem;
        }
        .progress .label {
            font-weight: 500;
            font-size: 1.6rem;
        }
        .progressbar-item {
            padding-bottom: var(--space-3);
            margin-bottom: var(--space-3);
        }
    }
}