@use "../config/" as *;
.load-bg-pattern {
    .waf-footballscorecard {
        @each $venue in $venueID {
            &.venue-#{$venue} {
                .masthead::after {
                    @include bgImg("club/venue/takeover/mobile/#{$venue}.jpg");
                }
            }
        }
    }
}
@each $club-id,
$club-color in $club-colors {
    .club-#{$club-id} {
        .masthead {
            @include bgImg("club-#{club-id}.png");
        }
    }
}
.waf-footballscorecard {
    padding: 0;
    @extend %mx-2-neg;
    .stories-section {
        height: 10rem;
    }
    > .layout-wrapper {
        > .waf-head {
            @extend %d-none;
        }
    }
    .penalty-aggregate {
        width: max-content;
        @extend %relative;
        @extend %mb-4-neg;
        @extend %white;
        .highlight {
            @extend %accent;
        }
        .penalty,
        .aggregate {
            @extend %white;
            @extend %font-12;
        }
    }
    .tab-title {
        @extend %font-18;
        @extend %white;
        @extend %text-center;
        @extend %uppercase;
    }
    .layout-wrapper {
        max-width: unset;
        @extend %w-100;
        @extend %m-0;
    }
    .team {
        gap: var(--space-4);
        @extend %flex-n-fs;
        &-wrap {
            padding-block: 4% 2%;
            gap: var(--space-2);
            @extend %relative;
            @extend %flex-c-fs;
        }
        &-a {
            .name {
                @extend %text-right;
            }
        }
        &-b {
            flex-direction: row-reverse;
            .name {
                @extend %text-left;
            }
        }
        &-image {
            width: 6rem;
            height: 6rem;
            overflow: hidden;
            @extend %mb-2;
            @extend %circle-radius;
            @extend %white-bg-3;
            @extend %p-1;
            @extend %relative;
            &::before {
                content: "";
                position: absolute;
                inset: 10%;
                @extend %circle-radius;
                @extend %m-auto;
                @extend %white-bg-8;
            }
            .image {
                width: 100%;
                height: 100%;
                object-fit: contain;
                @extend %p-1;
                @extend %relative;
            }
        }
        &-name {
            @extend %white;
            @extend %mb-0;
            @extend %text-center;
            &:hover {
                text-decoration: underline;
            }
        }
        &-score {
            height: 6rem;
            width: 2.5rem;
            @extend %flex-n-c;
        }
        &-status {
            height: 6rem;
            @extend %flex-column-c-c;
            @extend %gap-1;
            @extend %mx-2;
        }
        &-time-status {
            width: 6.5rem;
            height: 2.5rem;
            border: 0.1rem solid hsl(var(--hsl-white) / 0.3);
            @extend %relative;
            @extend %rounded-radius;
            @extend %flex-c-c;
            @extend %secondary-dark-bg;
            .text {
                width: max-content;
                font-size: 1rem;
                @extend %white;
            }
        }
        &-client {
            @include position(null, null, 1rem, 0);
            @extend %d-none;
            @extend %w-100;
            @extend %position-b-l;
            .image {
                height: 1.8rem;
                width: 7rem;
                object-fit: contain;
            }
            .link {
                width: max-content;
                margin-inline: auto;
                @extend %flex-c-c;
                &::before {
                    content: "Showing On";
                    white-space: nowrap;
                    width: max-content;
                    height: 1.3rem;
                    @extend %px-1;
                    @extend %rounded-radius;
                    @extend %success-bg;
                    @extend %white;
                    @extend %font-6-pb;
                    @extend %uppercase;
                    @extend %flex-c-c;
                }
            }
        }
    }
    .meta {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.6;
        @extend %white;
        &:first-child::after {
            content: "|";
            @extend %mx-1;
        }
    }
    .time::before {
        display: inline-block;
        @include icon(clock, 10);
        @extend %mr-1;
    }
    .venue {
        @extend %d-block;
        &::before {
            @include icon(stadium, 10);
            @extend %mr-1;
        }
    }
    .full,
    .match {
        @extend %d-none;
    }
    .name {
        @extend %font-18-pb;
        @extend %white;
    }
    .score {
        @extend %white-5;
        @extend %font-40-pb;
    }
}
.masthead {
    isolation: isolate;
    &::after {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        // @include bgImg("stadium/matchcenter/stadium-bg.png");
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: var(--z-negative);
    }
    // isolation: isolate;
    @extend %py-4;
    @extend %secondary-dark-bg;
    @extend %relative;
    .card {
        &-item {
            padding: var(--space-6) var(--space-5) var(--space-4);
            gap: 5%;
            background: url(/static-assets/images/cssimages/home-page/mobile/homepage-takeover-bg.png?v=#{$image-version}) center / cover no-repeat;
            @extend %h-100;
            @extend %quarter-radius;
            @extend %flex-column;
            @extend %relative;
            &.recent {
                .team-won {
                    .score {
                        @extend %accent;
                    }
                }
                .team-time-status {
                    background: linear-gradient(114deg,
                            var(--secondary-dark) 0%,
                            var(--secondary-dark) 70.56%,
                            var(--primary) 140%);
                }
            }
            &.live {
                isolation: isolate;
                &::before {
                    content: "";
                    background: linear-gradient(-45deg, var(--primary), var(--secondary-dark), var(--primary)) left center / 200% 200% no-repeat;
                    animation: gradient-bg 20s ease infinite alternate;
                    opacity: 0.7;
                    z-index: var(--z-negative);
                    pointer-events: none;
                    @extend %position-center;
                }
                &::after {
                    content: "";
                    // animation: zoombg 20s ease infinite alternate;
                    z-index: var(--z-negative);
                    pointer-events: none;
                    background-position: center;
                    background-size: cover;
                    // inset-inline: -40%;
                    @include bgImg("cssimages/live-pattern.png");
                    @extend %position-center;
                }
                .team-current {
                    .score {
                        @extend %white;
                    }
                }
            }
            &.upcoming {
                .team {
                    &-time-status {
                        width: 9rem;
                        height: 3rem;
                        @extend %secondary-bg;
                        .text {
                            font-size: 1.4rem;
                            &::before {
                                display: inline-block;
                                @include icon(clock, 12);
                                @extend %mr-1;
                            }
                        }
                    }
                    &-score {
                        @extend %d-none;
                        .score {
                            @extend %d-none;
                        }
                    }
                }
                .innings-wrap {
                    @extend %d-none;
                }
            }
            &.postponed {
                .team-time-status {
                    background-color: var(--dark-grey);
                    .text::before {
                        display: none;
                    }
                }
            }
        }
        &-number {
            width: fit-content;
            height: 2.6rem;
            position: absolute;
            top: 0;
            left: 50%;
            translate: -50% -50%;
            @extend %p-1-5;
            @extend %rounded-radius;
            @extend %flex-n-c;
            @extend %white-bg;
            @extend %text-center;
            .text {
                font-weight: 700;
                @extend %font-11;
                @extend %uppercase;
            }
        }
        &-head {
            @extend %p-1-3;
            @extend %half-radius;
            @extend %secondary-dark-bg-3;
        }
        &-meta {
            @extend %text-center;
            .date {
                @extend %uppercase;
            }
        }
        &-action {
            margin-top: auto;
            @extend %flex-column-n-c;
        }
        &-section {
            @extend %p-2;
        }
        &-footer {
            @include border(1, white, 1, top);
            @extend %flex-c-n;
            @extend %gap-2;
            @extend %flex-wrap;
            @extend %pt-5;
            @extend %pb-3;
            .client {
                &-list {
                    @extend %w-100;
                }
                &-item {
                    width: max-content;
                    @extend %m-auto;
                    @extend %flex-fe-c;
                    @extend %gap-2;
                    &::before {
                        content: "Showing On";
                        white-space: nowrap;
                        width: max-content;
                        padding: .2rem .5rem;
                        @extend %rounded-radius;
                        @extend %success-bg;
                        @extend %white;
                        @extend %font-8-pb;
                        @extend %uppercase;
                        @extend %flex-c-c;
                    }
                }
                &-img {
                    height: 3rem;
                }
            }
            .card-option {
                @extend %flex-c-c;
                @extend %flex-wrap;
                @extend %gap-3;
            }
            .btn {
                &-site {
                    width: 12rem;
                    padding: var(--space-1) var(--space-2);
                    @extend %flex-c-c;
                    @extend %gap-1;
                    @extend %quarter-radius;
                    @extend %hidden;
                    .text {
                        @extend %font-10-pr;
                    }
                }
                &-preview,
                &-report,
                &-highlight {
                    background-color: transparent;
                    @include border(1, white, 7);
                    @extend %white;
                }
                &-ticket {
                    &::before {
                        @include icon(ticket, 10);
                    }
                }
                &-highlight {
                    &::before {
                        @include icon(play, 10);
                    }
                }
                &-report {
                    &::before {
                        @include icon(file, 10);
                    }
                }
                &-preview {
                    &::before {
                        @include icon(preview, 10);
                    }
                }
            }
        }
    }
}
.innings {
    &-wrap {
        @extend %mx-4-neg;
        @extend %relative;
        @extend %white;
        @extend %pb-8;
        .divider {
            width: 0.1rem;
            position: absolute;
            top: 0;
            left: calc(50% + 0.05rem);
            @extend %h-100;
            @extend %white-bg-4;
            &::before,
            &::after {
                content: "";
                width: 0.6rem;
                height: 0.6rem;
                position: absolute;
                top: 0;
                left: -0.3rem;
                @extend %circle-radius;
                @extend %white-bg;
            }
            &::after {
                top: unset;
                bottom: 0;
            }
        }
        .btn-more {
            width: 6rem;
            height: 1.8rem;
            border-radius: var(--half-radius) var(--half-radius) 0 0;
            left: calc(50% - 2.5rem);
            @extend %transition;
            @extend %flex-c-c;
            @extend %p-0;
            @extend %position-b-l;
            .btn-text {
                font-size: 0;
            }
            &::after {
                @include icon(double-chevron-down, 8);
            }
            &[aria-expanded="true"] {
                &::after {
                    transform: rotate(180deg);
                }
                & ~ .innings-info {
                    max-height: unset;
                }
            }
        }
    }
    &-info {
        max-height: 12rem;
        @extend %hidden;
        @extend %py-2;
        @extend %relative;
        @extend %flex-c-n;
        @extend %gap-1;
        .player {
            &-details {
                width: calc(50% - var(--space-2));
            }
            &-info {
                gap: .3rem;
                cursor: pointer;
                @extend %flex-wrap;
                @extend %flex-n-c;
                @extend %py-1;
                @extend %font-10-pm;
                @extend %white;
                &::before {
                    content: "";
                    width: 1rem;
                    height: 1rem;
                    @include icon(football);
                    @extend %accent;
                    @extend %mx-1;
                    @extend %circle-radius;
                }
                &:hover {
                    text-decoration: underline;
                }
            }
            &-score {
                opacity: 0.5;
                @extend %font-8-pr;
            }
        }
        .left-section {
            .player-info {
                justify-content: flex-end;
                &::before {
                    order: 2;
                }
            }
        }
    }
}
.no-datawrap {
    @extend %w-100;
    @extend %text-center;
    @extend %p-3;
    .no-data {
        @extend %font-20-pb;
    }
}
.sticky {
    .header-wrap {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        width: 100%;
        overflow: hidden;
    }
    .waf-footballscorecard {
        .team {
            align-items: center;
            .name {
                @extend %font-12;
            }
            &-wrap {
                position: fixed;
                top: 0;
                left: 0;
                align-items: center;
                height: var(--sticky-scores);
                z-index: var(--z-sticky-match);
                background: url("/static-assets/images/cssimages/home-page/mobile/homepage-takeover-bg.png") center/cover no-repeat;
                @extend %secondary-dark-bg;
                @extend %w-100;
                @extend %gap-2;
                @extend %py-1;
            }
            &-image {
                margin: 0;
                width: 3rem;
                height: 3rem;
                padding: .3rem;
                .image {
                    padding: 0;
                }
            }
            &-score {
                height: 2rem;
                width: 2rem;
                .score {
                    @extend %font-26;
                }
            }
            &-info {
                @extend %flex-n-c;
                @extend %gap-2;
            }
            &-status {
                height: 2rem;
            }
            &-a {
                .team-info {
                    flex-direction: row-reverse;
                }
            }
        }
        .live {
            isolation: unset;
            .team-wrap {
                isolation: isolate;
                &::before {
                    content: "";
                    background: linear-gradient(-45deg, var(--primary), var(--secondary-dark), var(--primary)) left center / 200% 200% no-repeat;
                    animation: gradient-bg 20s ease infinite alternate;
                    opacity: 0.7;
                    z-index: var(--z-negative);
                    @extend %position-center;
                }
                &::after {
                    content: "";
                    z-index: var(--z-negative);
                    background-size: cover;
                    @include bgImg("cssimages/live-pattern.png");
                    @extend %position-center;
                }
            }
        }
        .team-client {
            @extend %d-none;
        }
    }
    .innings-wrap {
        margin-top: 16.5rem;
    }
    .penalty-aggregate {
        @extend %d-none;
    }
    .masthead {
        z-index: var(--z-sticky-team);
    }
}
@include mq(col-md) {
    .waf-footballscorecard {
        .tab-title {
            font-size: 2.4rem;
        }
        .masthead {
            padding: var(--space-8) var(--space-8) var(--space-5);
            .penalty-aggregate {
                .penalty,
                .aggregate {
                    font-size: 1.4rem;
                }
            }
        }
        .card {
            &-head {
                width: max-content;
                margin: auto;
            }
            &-footer {
                .client-list {
                    display: block;
                }
                .team-client {
                    display: block;
                }
                .card-option {
                    position: absolute;
                    right: 3rem;
                    bottom: 3rem;
                }
                .btn-site {
                    width: 15rem;
                    margin-top: 1rem;
                    padding-inline: var(--space-3);
                    &::before {
                        font-size: 1.4rem;
                    }
                    .text {
                        font-size: 1.4rem;
                    }
                }
            }
        }
        .meta {
            font-size: 1.2rem;
            display: inline;
            width: max-content;
            &::before {
                font-size: 1.2rem;
            }
        }
        .time {
            &::after {
                content: "|";
                margin-inline: var(--space-1);
            }
        }
        .team {
            &-info {
                display: flex;
                gap: var(--space-4);
                align-items: center;
                width: 30rem;
            }
            &-a {
                .team-info {
                    flex-direction: row-reverse;
                }
            }
            &-name {
                max-width: 20rem;
                font-size: 2.4rem;
            }
            &-score {
                width: 5rem;
                .score {
                    font-size: 7rem;
                }
            }
            &-wrap {
                gap: var(--space-6);
            }
            &-image {
                margin: 0;
                width: 7rem;
                height: 7rem;
            }
            &-time-status {
                width: 13rem;
                height: 4.5rem;
                .text {
                    font-size: 2.2rem;
                }
            }
            &-status {
                gap: var(--space-2);
            }
            &-client {
                display: none;
            }
        }
        .name {
            font-size: 2.2rem;
        }
        .short {
            display: none;
        }
        .full {
            display: block;
        }
        .innings {
            &-info {
                max-width: 70rem;
                gap: 6rem;
                margin: var(--space-3) auto;
                position: relative;
            }
            &-wrap {
                position: relative;
                .btn-more {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
        .player-details {
            margin: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            gap: 0 var(--space-2);
            .player {
                &-info {
                    font-size: 1.2rem;
                    &::before {
                        font-size: 1rem;
                        order: unset;
                        display: flex;
                        align-items: center;
                    }
                }
                &-name {
                    font-size: 1.2rem;
                }
                &-score {
                    font-size: 1rem;
                }
            }
        }
        .right-section {
            justify-content: flex-start;
        }
    }
    .innings-info {
        max-height: 13.5rem;
    }
    .sticky {
        .waf-footballscorecard {
            .team {
                &-image {
                    width: 4rem;
                    height: 4rem;
                }
            }
        }
    }
    .load-bg-pattern {
        .waf-footballscorecard {
            @each $venue in $venueID {
                &.venue-#{$venue} {
                    .masthead::after {
                        @include bgImg("club/venue/takeover/#{$venue}.jpg");
                    }
                }
            }
        }
    }
}