@use '../config/' as *;
.player-profile {
    overflow: auto;
    z-index: var(--z-players-modal);
    background-image: $gradient;
    isolation: isolate;
    @extend %medium-grey-bg;
    @extend %p-3;
    &::after {
        content: "";
        z-index: var(--z-negative);
        opacity: .5;
        position: fixed;
        @extend %position-center;
    }
    .modal {
        &-head {
            height: unset;
        }
        &-body {
            height: unset;
            flex-direction: column;
            @extend %p-0;
        }
    }
    .card {
        &-thumbnail {
            z-index: var(--z-negative);
            @extend %relative;
            @extend %m-auto;
            .image {
                width: var(--player-width);
                aspect-ratio: 1/1;
                -webkit-mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 40%, transparent 94%, transparent 100%);
                mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 40%, transparent 94%, transparent 100%);
                @extend %m-auto;
            }
            &::after {
                content: "";
                background: transparent;
                width: calc(var(--player-width) + 2rem);
                height: calc(var(--player-width) + 2rem);
                position: absolute;
                top: calc(18% - 1rem);
                left: -1rem;
                right: -1rem;
                margin: auto;
                border-radius: 50%;
                border: .1rem dashed var(--white);
                z-index: var(--z-negative);
                opacity: 0.4;
                -webkit-mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 15%, transparent 60%, transparent 100%);
                mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 15%, transparent 60%, transparent 100%);
            }
            &::before {
                content: "";
                top: 18%;
                margin: auto;
                width: var(--player-width);
                height: var(--player-width);
                -webkit-mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 15%, transparent 60%, transparent 100%);
                mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 15%, transparent 60%, transparent 100%);
                @extend %club-secondary-bg;
                @extend %circle-radius;
                @extend %position-x-center;
            }
        }
        &-info-wrapper {
            @extend %w-100;
            @extend %white;
            @extend %relative;
            a,
            button {
                @extend %white;
            }
        }
        &-number {
            -webkit-text-stroke: 0.1rem hsl(var(--hsl-white)/0.5);
            color: transparent;
            right: var(--space-4);
            @extend %position-t-r;
            @extend %font-50-pb;
        }
        &-name {
            max-width: 27rem;
            @extend %m-auto;
            .name {
                @extend %font-18-pr;
            }
            .lname {
                @extend %font-18-pb;
            }
        }
        &-meta {
            &.meta-a {
                @extend %mt-4-neg;
            }
            &.meta-b {
                max-width: 27rem;
                border-top: 0.05rem solid hsl(var(--hsl-white)/0.5);
                padding-block: var(--space-2);
                @extend %flex-n-c;
                @extend %my-2;
                @extend %gap-3;
                @extend %mx-auto;
            }
            &-item {
                flex: 1;
                min-width: max-content;
                @extend %uppercase;
                @extend %flex-column-c-fs;
                @extend %pr-3;
                &:where(:not(:last-child)) {
                    @include separator(80%, y);
                }
            }
            .country {
                .image {
                    width: 3.4rem;
                    height: 2rem;
                }
                .card-text {
                    @extend %flex-n-c;
                    @extend %gap-2;
                }
            }
        }
        &-label {
            @extend %font-12-pr;
        }
        &-text {
            @extend %font-20-pb;
        }
        &-skills {
            width: max-content;
            margin-left: calc((100% - 27rem)/2);
            @extend %font-8;
            @extend %px-1;
            @extend %white-bg-1;
        }
    }
    .player {
        &-stats {
            &-wrapper {
                @extend %secondary-dark;
            }
        }
        &-form {
            gap: 0 var(--space-2);
            @extend %flex;
            @extend %flex-wrap;
        }
    }
    .select-player {
        @extend %mt-4;
    }
    .card {
        &-number {
            position: unset;
            grid-row-end: span 2;
            @include separator(80%, y)
        }
        &-meta {
            display: grid;
            align-content: center;
            grid-template-columns: 10rem 1fr;
            width: max-content;
            @extend %m-auto;
        }
        &-skills {
            margin: 0;
        }
        &-name {
            @extend %font-20;
            @extend %flex-column;
        }
        &-info-wrapper {
            isolation: isolate;
            @extend %mt-10;
        }
    }
    .btn-close {
        top: 4rem;
        right: 5rem;
        position: absolute;
    }
    .player-stats {
        &-value {
            line-height: 1.2;
            @extend %mb-2;
        }
        &-wrapper {
            .has-progress {
                grid-column-end: span 6;
                .stats-item-data {
                    flex-direction: row-reverse;
                }
                .player-stats {
                    &-title {
                        width: 10rem;
                        @extend %text-left;
                        @extend %font-14;
                    }
                    &-value {
                        @extend %font-20;
                        @extend %mb-0;
                        @extend %mr-3;
                    }
                }
            }
            .touches {
                flex-direction: row;
                background-color: transparent;
                @extend %gap-3;
                .stats-item {
                    &-media {
                        width: 8rem;
                        height: 8rem;
                        flex-shrink: 0;
                        margin-bottom: 0;
                    }
                    &-data {
                        flex-direction: row-reverse;
                    }
                }
                .player-stats {
                    &-title {
                        width: 10rem;
                        @extend %text-left;
                        @extend %font-14;
                    }
                    &-value {
                        position: relative;
                        left: -5rem;
                        @extend %font-20;
                        @extend %mb-0;
                        @extend %ml-4-neg;
                    }
                }
            }
            &:last-child {
                .stats-item {
                    position: relative;
                    grid-column-end: span 2;
                    flex-direction: column;
                    .player-stats {
                        &-value {
                            width: 3rem;
                            position: absolute;
                            top: 5rem;
                            inset-inline: 0;
                            margin: auto;
                            @extend %flex-c-c;
                        }
                        &-title {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
    .stats {
        &-list {
            grid-template-columns: repeat(6, 1fr);
            // taking 3 cols at once
            .large-col {
                grid-column-end: span 3;
                .player-stats-value {
                    width: 3rem;
                }
            }
            // if have a graph
            .has-progress,
            .pass-accuracy {
                grid-column-end: span 4;
                flex-direction: row;
                background-color: transparent;
                @extend %gap-3;
                .stats-item {
                    &-media {
                        width: 8rem;
                        height: 8rem;
                        flex-shrink: 0;
                        margin-bottom: 0;
                    }
                    &-data {
                        flex-direction: row-reverse;
                    }
                }
                .player-stats {
                    &-title {
                        width: 10rem;
                        flex-shrink: 0;
                        @extend %text-left;
                        @extend %font-14;
                    }
                    &-value {
                        position: relative;
                        left: -5rem;
                        width: 3rem;
                        @extend %font-20;
                        @extend %mb-0;
                        @extend %ml-4-neg;
                        @extend %flex-c-n;
                    }
                }
            }
        }
        &-item {
            grid-column-end: span 2;
        }
    }
}
@include mq(col-md) {
    .player-profile {
        padding-inline: 20rem;
        --player-width: 27rem;
        .card {
            &-info-wrapper {
                display: flex;
                align-items: end;
                justify-content: center;
                padding: 0;
                margin: 0;
                z-index: var(--z-card-info);
            }
            &-meta {
                margin: var(--space-4) 0;
                flex-direction: column;
                text-align: left;
                &.meta-a {
                    order: -1;
                }
                &.meta-b {
                    margin: 0;
                    border: 0;
                    gap: 0;
                }
                &-item {
                    width: 100%;
                    padding-block: var(--space-4);
                    &:not(:last-child) {
                        @include separator(100%, x);
                        &::after {
                            top: unset;
                        }
                    }
                }
            }
            &-thumbnail {
                width: var(--player-width);
                margin: 0 10rem;
                .image {
                    -webkit-mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 70%, transparent 120%);
                    mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 70%, transparent 120%);
                }
                &::before {
                    -webkit-mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 60%, transparent 80%);
                    mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 60%, transparent 80%);
                }
                &::after {
                    -webkit-mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 60%, transparent 80%);
                    mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 60%, transparent 80%);
                }
            }
            &-number {
                line-height: 1;
                padding-block: var(--space-3);
                right: 0;
                font-size: 7rem;
                @include separator(100%, x);
            }
            &-skills {
                margin: 0;
            }
            &-name {
                margin-block: var(--space-3);
                line-height: 1;
                .name {
                    font-size: 2.4rem;
                    display: block;
                }
            }
            &-skills {
                font-size: 1.2rem;
            }
        }
        .modal {
            &-head {
                padding-bottom: 0;
                padding-inline: 10rem;
            }
            &-body {
                padding: 0rem;
                max-width: unset;
            }
        }
        .card {
            &-meta {
                display: flex;
                margin-inline: var(--space-6);
            }
            &-number {
                font-size: 7rem;
                @include separator(100%, x);
                &::after {
                    top: unset;
                }
            }
            &-name {
                width: 100%;
                .name {
                    font-size: 2.4rem;
                }
            }
            &-thumbnail {
                margin-right: 4rem;
                margin-left: 0;
            }
        }
        .select-player {
            margin-left: 5rem;
        }
        .btn-close {
            right: 22rem;
        }
        .stats-list {
            .pass-accuracy {
                width: calc((100% / 2) - 10rem);
            }
        }
        .player-stats-wrapper:last-child {
            .stats-item {
                width: calc((100% / 3) - 4rem);
            }
        }
    }
}