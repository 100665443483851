@use '../config/' as *;
.accordion {
    &-btn {
        padding-inline: var(--space-2);
        box-shadow: 0rem .4rem .4rem 0rem hsl(var(--hsl-black)/0.25);
        z-index: var(--z-timeline);
        transform: translateY(12rem);
        width: max-content;
        transition: transform 300ms linear;
        @include position-combo(x-center, null, calc(var(--timeline-height) + 4rem), fixed);
        @extend %btn-fill;
        @extend %accent-bg;
        @extend %secondary;
        @extend %flex;
        @extend %gap-3;
        @extend %quarter-radius;
        .btn-text {
            @extend %secondary-dark;
        }
        &::after {
            transition: transform 300ms linear;
            @include icon(chevron-up);
            @extend %secondary-dark;
        }
        &[aria-expanded=true] {
            transform: translateY(3rem);
            border-radius: var(--quarter-radius) var(--quarter-radius) 0 0;
            & ~ .accordion-body {
                transform: translateY(0rem);
                pointer-events: initial;
            }
            &::after {
                transform: rotate(180deg);
            }
        }
    }
    &-body {
        --progress-height: 0.5rem;
        position: fixed;
        height: 14rem;
        bottom: 0;
        left: 0;
        z-index: var(--z-timeline);
        transform: translateY(20rem);
        background-size: cover;
        pointer-events: none;
        @include border(1, primary, 10, top);
        @extend %flex;
        @extend %p-3;
        @extend %transition;
        @extend %secondary-dark-bg;
        @extend %w-100;
        @extend %white;
    }
}
.timeline {
    // tabs style
    &-tabs {
        flex-shrink: 0;
        @extend %white-bg-1;
        @extend %quarter-radius;
        &-list {
            cursor: pointer;
            list-style: none;
            @extend %p-2;
        }
        &-input {
            cursor: pointer;
            appearance: none;
            width: 1rem;
            aspect-ratio: 1/1;
            outline: .1rem solid var(--white);
            outline-offset: 0.2rem;
            border-radius: 50%;
            opacity: 0.7;
            transition: opacity 300ms, background-color 300ms;
            margin-right: 0.5rem;
            &:checked {
                opacity: 1;
                background-color: var(--primary);
            }
            &:focus-within {
                outline: .1rem solid var(--white);
                outline-offset: 0.2rem;
            }
        }
        &-text {
            cursor: pointer;
        }
        &-item {
            cursor: pointer;
            @extend %flex-n-c;
            @extend %gap-1;
            &:not(:last-child) {
                @extend %mb-1;
            }
        }
    }
    // logo style
    &-logo {
        width: 3.6rem;
        height: 3.6rem;
        @extend %p-1;
        @extend %white-bg;
        @extend %circle-radius;
        @extend %hidden;
        .logo-image {
            @extend %w-100;
            @extend %h-100;
        }
        &-wrap {
            @extend %flex-column-sb-n;
            @extend %h-100;
            .timeline-event {
                flex: unset;
                width: 3.6rem;
                border-radius: var(--half-radius) 0 0 var(--half-radius);
            }
        }
    }
    // progressbar style
    &-progress {
        flex: 1;
        @extend %flex-n-c;
        &-ht-active {
            .timeline-event:not(:nth-child(-n+47)) {
                width: 0;
                flex: 0;
                overflow: hidden;
                opacity: 0;
                pointer-events: none;
                margin: 0;
                padding: 0;
                height: 0;
            }
        }
        &-ft-active {
            .timeline-event:not(:nth-child(n+47):nth-child(-n+92)) {
                width: 0;
                flex: 0;
                overflow: hidden;
                opacity: 0;
                pointer-events: none;
                margin: 0;
                padding: 0;
                height: 0;
            }
        }
        &-aet-active {
            .timeline-event:not(:nth-child(n+91)) {
                width: 0;
                flex: 0;
                overflow: hidden;
                opacity: 0;
                pointer-events: none;
                margin: 0;
                padding: 0;
                height: 0;
            }
        }
    }
    &-event {
        height: .5rem;
        flex: 1;
        transition: flex 300ms;
        @extend %flex-c-c;
        @extend %white-bg-2;
        @extend %relative;
        &.timeline-event-active {
            @extend %accent-bg;
        }
        &-ko,
        &-ht,
        &-ft,
        &-eht,
        &-aet,
        &-has-event {
            flex: unset;
            width: max-content;
            margin-inline: .2rem;
            background: transparent;
            &.timeline-event-active {
                background: transparent;
            }
        }
        &-has-event + &-has-event {
            @extend %pl-1;
            &::after {
                content: "";
                width: 0.7rem;
                height: var(--progress-height);
                position: absolute;
                top: 0;
                left: -0.3rem;
                @extend %white-bg-2;
            }
            &.timeline-event-active::after {
                @extend %accent-bg;
            }
        }
    }
    // ko, ht, ft, eht, aet style
    &-interval {
        border-radius: .2rem;
        @include border(1, white, 4);
        @include separator(1rem, y);
        @extend %font-14-pb;
        @extend %uppercase;
        @extend %secondary-bg;
        @extend %px-2;
        &::after {
            left: 50%;
            top: -3.5rem;
            z-index: var(--z-negative);
        }
        &::before {
            content: "";
            width: .1rem;
            height: 1rem;
            position: absolute;
            inset-block: 0;
            left: 50%;
            bottom: -3.5rem;
            @extend %my-auto;
        }
        &::after,
        &::before {
            background-color: var(--primary);
        }
        &-aet,
        &-eht {
            position: unset;
            width: auto;
            @extend %p-0;
        }
    }
    &-wrapper {
        @extend %px-6;
        @extend %w-100;
        @extend %flex;
        @extend %h-100;
        @extend %relative;
    }
    &-container {
        .timeline-wrapper {
            .time::before,
            .time::after {
                @extend %d-none;
            }
        }
    }
}
// events style
.event {
    &-item {
        height: 10rem;
        @extend %flex-column-c-c;
        @extend %relative;
        &::after,
        &::before {
            content: "";
            width: .1rem;
            height: 1rem;
            position: absolute;
            @extend %warning-bg;
            @extend %my-auto;
        }
        
        .time {
            min-width: 2rem;
            z-index: var(--z-timeline-symbol);
            @extend %flex-c-c;
            @extend %white;
            @extend %font-12-pb;
        }
        .player-img {
            width: 8rem;
            height: 8rem;
        }
        // --------------
        &.team-a {
            .event-tooltip {
                bottom: 10.5rem;
            }
            .event-symbol {
                top: 0;
                bottom: unset;
            }
            &::before {
                top: 2.8rem;
            }
            &::after {
                @extend %d-none;
            }
        }
        &.team-b {
            &::after {
                bottom: 2.8rem;
            }
            &::before {
                @extend %d-none;
            }
        }
        &-goal {
            .event-symbol {
                &::after {
                    @include icon(football, 20)
                }
            }
        }
        &-yellow-card {
            .event-symbol {
                &::after {
                    @include icon(card, 20);
                    @extend %warning;
                }
            }
        }
        &-red-card {
            .event-symbol {
                &::after {
                    @include icon(card, 20);
                    @extend %primary;
                }
            }
        }
        &-substitution {
            .event-symbol {
                &::after {
                    @include border(1, white);
                    @include icon(substitution, 14);
                    @extend %circle-radius;
                    @extend %subsitution;
                }
            }
        }
        &-plus {
            &.team-a.team-b {
                &::before {
                    display: block;
                    top: 2.8rem;
                }
            }
            .event-symbol {
                &::after {
                    @include icon(plus, 20);
                    @extend %accent;
                }
            }
        }
        & + & {
            margin-left: -2rem;
            .event-time .time {
                opacity: 0;
            }
        }
    }
    &-wrap {
        flex: 1;
    }
    &-player {
        flex-shrink: 0;
    }
    &-symbol {
        z-index: var(--z-timeline-symbol);
        flex-shrink: 0;
        aspect-ratio: 1/1;
        bottom: 0;
        @extend %position-x-center;
        &::after {
            width: 2rem;
            height: 2rem;
            @extend %flex-c-c;
            // @include icon(football, 20);
        }
        &:hover ~ .event-tooltip {
            flex-shrink: 0;
            opacity: 1;
            pointer-events: initial;
            z-index: var(--z-timeline-tooltip);
        }
    }
    &-content {
        @extend %w-100;
        hr,
        .hyphen {
            @extend %d-none;
        }

        .player {
            &-list {
                @extend %flex;
                @extend %gap-1;
            }
            &-name {
                width: max-content;
                .name {
                    @extend %secondary-dark;
                    @extend %font-12-pb;
                }
            }
            &-assist {
                @extend %w-100;
                @extend %font-8-pr;
            }
            &-item {
                flex: 1;
                @extend %my-1;
            }
            &-data {
                align-content: center;
                gap: 0 var(--space-1);
                @extend %flex;
                @extend %flex-wrap;
            }
            &-substitution {
                @extend %font-12-pb;
                @extend %capitalize;
            }
            &-in {
                @extend %flex-n-c;
                .player-data {
                    width: max-content;
                }
                .player-substitution {
                    @extend %success;
                    &::after {
                        @include icon(long-up-arrow)
                    }
                }
            }
            &-out {
                .player-data {
                    width: max-content;
                }
                .player-substitution {
                    @extend %error;
                    &::after {
                        @include icon(long-down-arrow)
                    }
                }
            }
        }
    }
    // tooltip section
    &-type {
        @include separator(100%, x, 0.1rem, secondary, 6);
        @extend %font-14-pb;
        @extend %secondary;
        @extend %pb-1;
        @extend %mb-1;
        @extend %flex-n-c;
        .text {
            @extend %uppercase;
            @extend %mx-1;
        }
        .time {
            position: unset;
            align-items: center;
            font-weight: 400;
            color: var(--secondary);
            @extend %m-0;
        }
    }
    &-tooltip {
        width: 28.6rem;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        left: 50%;
        bottom: 3rem;
        transform: translateX(-50%);
        min-width: max-content;
        z-index: var(--z-timeline-tooltip);
        transition: opacity 0.4s;
        @extend %half-radius;
        @extend %white-bg;
        @extend %relative;
        &-list {
            max-height: calc(var(--window-inner-height) - 24rem);
            overflow: auto;
        }
        &:hover {
            opacity: 1;
            pointer-events: initial
        }
        &::after {
            content: "";
            width: 1rem;
            height: 1rem;
            bottom: -0.5rem;
            transform: rotate(45deg);
            @extend %white-bg;
            @extend %position-x-center;
        }
    }
    &-card {
        @extend %px-2;
        @extend %pt-1;
        @extend %w-100;
        @extend %secondary-dark;
        @extend %relative;
        @extend %flex;
        &:before {
            content: "";
            width: .4rem;
            background: linear-gradient(180.56deg, var(--secondary-light) 3.24%, var(--primary) 125.31%);
            position: absolute;
            inset-block: 0;
            left: 0;
            @extend %my-auto;
            @extend %h-50;
        }
        &:not(:last-child) {
            @include separator(90%, x, 0.1rem, secondary, 7);
        }
        &.event-red-card {
            .icon::after {
                @extend %primary;
                @include icon(card);
            }
        }
        &.event-goal {
            .icon::after {
                @include icon(football);
            }
        }
        &.event-yellow-card {
            .icon::after {
                @extend %warning;
                @include icon(card);
            }
        }
        &.event-substitution {
            .icon {
                &::after {
                    background-image: linear-gradient(95deg, var(--error) 50%, var(--success) 50%);
                    -webkit-text-fill-color: transparent;
                    -webkit-background-clip: text;
                    @include icon(substitution);
                }
            }
        }
    }
    &-player {
        @extend %pt-2;
    }
    &-common {
        .event-symbol {
            z-index: var(--z-event-common-symbol);
        }
    }
}
.substitution {
    &-item {
        @extend %flex;

        .player-in {
            order: -1;
        }
    }
}