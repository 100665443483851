@use "../config" as *;
.swiper {
	&-button-next,
	&-button-prev {
		width: var(--swiper-button-width);
		height: var(--swiper-button-width);
		top: 0;
		bottom: 0;
		margin: auto;
		border-radius: 0.3rem;
		@extend %secondary-light;
		@extend %flex-c-c;
		@extend %white-bg;
		&.swiper-button-disabled {
			cursor: not-allowed;
			@extend %white;
			@extend %white-bg-5;
			&::after {
				@extend %secondary-light-4;
			}
		}
		&::after {
			font: 1.6rem/1 $font-icon;
		}
	}
	&-button-prev {
		&::after {
			@include icon(chevron-left);
		}
	}
	&-button-next {
		&::after {
			@include icon(chevron-right);
		}
	}
	&-pagination {
		@extend %flex-c-c;
		@extend %w-100;
		@extend %gap-1;
	}
	&-pagination-bullet {
		width: 1rem;
		height: 1rem;
		@extend %white-bg-6;
		@extend %circle-radius;
		&.swiper-pagination-bullet-active {
			@include bg(primary);
		}
		&-active {
			&-next {
				transform: scale(0.66);
				&-next {
					transform: scale(0.33);
				}
			}
			&-prev {
				transform: scale(0.66);
				&-prev {
					transform: scale(0.33);
				}
			}
		}
	}
}