@use '../config/' as *;
.lineup {
    @extend %p-3;
    &-head {
        @include border(1, white, 1, bottom);
        @extend %flex-sb-c;
        @extend %pb-2;
        .team {
            &-score-info {
                @extend %flex;
                @extend %gap-3;
            }
            &-img {
                width: 4.2rem;
                height: 4.2rem;
                @extend %p-1;
                @extend %circle-radius;
                @extend %white-bg;
            }
            &-info-data {
                @extend %flex-column-fs-n;
            }
            &-name {
                @extend %text-left;
                .name {
                    @extend %font-12-pb;
                }
            }
            &-text {
                @extend %font-8;
            }
            &-lineup {
                @extend %font-8-pb;
            }
        }
    }
    &-body {
        .player {
            &-container {
                @extend %mb-4;
            }
            &-list {
                list-style: none;
                @extend %p-0;
            }
            &-item {
                cursor: pointer;
                @extend %pt-3;
                @extend %gap-3;
                @extend %flex;
                @include border(1, white, 1, bottom);
                &:hover {
                    .player {
                        &-number {
                            text-shadow: none;
                            @extend %white;
                        }
                        &-name {
                            .name {
                                text-decoration: underline;
                                @extend %accent;
                            }
                        }
                        &-action {
                            opacity: 1;
                            transform: translateX(1rem)
                        }
                    }
                }
            }
            &-event {
                @extend %ml-auto;
            }
            &-thumbnail {
                flex-shrink: 0;
                @extend %flex-n-c;
                @extend %gap-2;
            }
            &-image {
                flex-shrink: 0;
                width: 8rem;
                align-self: flex-end;
                @include border(1, white, 10, bottom);
                @extend %d-block;
            }
            &-number {
                -webkit-text-stroke-width: .05rem;
                -webkit-text-stroke-color: var(--white);
                color: transparent;
                min-width: 3rem;
                @extend %text-center;
                @extend %transition;
                @extend %font-22-pb;
                // @extend %secondary-dark;
            }
            &-content {
                align-content: flex-end;
                @extend %gap-1;
                @extend %pb-2;
                @extend %flex-n-c;
                @extend %flex-wrap;
            }
            &-name {
                max-width: 10rem;
                line-height: 1;
                // word-break: break-all;
                @extend %w-60;
                @extend %mb-0;
                .name {
                    line-height: 1.2;
                    @extend %font-10-pr;
                    @extend %uppercase;
                    @extend %d-block;
                }
                .last-name {
                    @extend %font-10-pb;
                }
            }
            &-role {
                width: max-content;
                @extend %px-2;
                @extend %uppercase;
                @extend %font-8;
                @extend %white-bg-2;
            }
            &-link {
                @extend %d-none;
            }
            &-action {
                opacity: 0.4;
                width: max-content;
                @extend %transition;
                .btn-site {
                    @extend %transparent-bg;
                    @extend %white;
                    @extend %font-0;
                    @extend %p-0;
                    &::after {
                        @include icon(arrow-right, 24);
                    }
                }
            }
            &-meta {
                order: 1;
                @extend %w-100;
            }
            &-icon {
                @extend %flex;
                @extend %text-center;
            }
            &-event {
                @extend %flex-n-c;
                @extend %text-center;
                @extend %gap-3;
                .name {
                    width: max-content;
                    @extend %font-10-pm;
                    @extend %d-block;
                }
                .timeline {
                    width: max-content;
                    @extend %font-10;
                    @extend %m-auto;
                    @extend %d-block;
                }
            }
            &-text {
                @extend %text-center;
            }
        }
    }
    .red-card {
        .icon {
            @extend %error;
        }
    }
    .yellow-card {
        .icon {
            @extend %warning;
        }
    }
    .goal {
        .icon {
            @extend %accent;
        }
    }
    .substitution {
        .icon {
            @extend %subsitution;
        }
    }
    .second-yellow {
        .icon {
            @extend %mx-auto;
            &::before {
                content: "";
                width: 2rem;
                height: 2rem;
                @extend %flex-c-c;
                @extend %second-yellow;
            }
        }
    }
    .fullname {
        @extend %d-none;
    }
    .tertiary-tabs {
        @extend %gap-2;
        .tab {
            &-item {
                @extend %d-none;
            }
            &-name {
                @extend %p-1-2;
                &::after {
                    @extend %d-none;
                }
            }
            &-ground {
                &::before {
                    line-height: 0.8;
                    @include icon(stadium-1, 20);
                }
            }
            &-list {
                &::before {
                    line-height: 0.8;
                    @include icon(list, 20);
                }
            }
        }
    }
    .playing-player-list {
        background-size: 100%;
        aspect-ratio: 5/7;
        @include bgImg("svg/pitch.svg");
        .event-tooltip {
            @extend %d-none;
        }
        .event-list {
            @extend %d-none;
        }
        .player {
            &-item {
                width: 20%;
                border: 0;
                @extend %flex-column-fs-n;
                @extend %gap-1;
                .thumbnail {
                    width: 3rem;
                    @extend %m-auto;
                    @extend %relative;
                }
            }
            &-number {
                -webkit-text-stroke: unset;
                width: 2.5rem;
                @extend %flex-c-c;
                @extend %position-center;
                @extend %white;
                @extend %mb-2;
                @extend %font-8;
            }
            &-name {
                @extend %text-center;
                @extend %font-10-pb;
                @extend %m-auto;
                .fname {
                    @extend %font-10-pr;
                }
            }
            &-list {
                // flex-basis: 16%;
                @extend %flex-c-c;
                &-wrap {
                    padding-block: var(--space-1);
                    gap: 4%;
                    @extend %h-100;
                    @extend %flex-column-fs-n;
                }
            }
        }
    }
    // .modal-window {
    //     position: fixed;
    //     top: 0;
    //     left: 0;
    //     @extend %w-100;
    //     @extend %h-100;
    //     @extend %y-auto;
    // }
}
.count-hidden {
    .player-thumbnail {
        .player-number {
            display: none;
        }
    }
}
@include mq(col-md) {
    .lineup {
        padding: var(--space-6);
        &-score-info {
            padding-bottom: var(--space-6);
        }
        &-head {
            .team {
                &-img {
                    width: 6rem;
                    height: 6rem;
                }
                &-name {
                    .name {
                        font-size: 2rem;
                    }
                }
                &-text {
                    font-size: 1.2rem;
                }
                &-lineup {
                    font-size: 1.2rem;
                }
            }
            .tertiary-tabs {
                .tab-name {
                    &::after {
                        font-size: 2rem;
                    }
                }
            }
        }
        &-body {
            .player {
                &-name {
                    max-width: unset;
                }
                &-number {
                    font-size: 3rem;
                }
            }
        }
        .fullname {
            display: block;
        }
        .shortname {
            display: none;
        }
        .playing-player-list {
            .player-list-wrap {
                gap: 3%;
            }
        }
    }
}
@include mq(col-lg) {
    .lineup .playing-player-list {
        .player {
            &-item .thumbnail {
                width: 5rem;
            }
            &-name {
                font-size: 1.4rem;
                .fname {
                    font-size: 1.4rem;
                }
            }
            &-number {
                font-size: 1.6rem;
            }
            &-list-wrap {
                padding-block: var(--space-4);
                gap: 9%;
            }
        }
    }
}
@include mq(col-xxl) {
    .lineup .playing-player-list {
        .player {
            &-list-wrap {
                padding-block: var(--space-6);
                gap: 14%;
            }
        }
    }
}