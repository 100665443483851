@use '../config/' as *;
.tab-section {
    list-style: none;
    @extend %flex-n-n;
    @extend %p-0;
    .tab-name {
        cursor: pointer;
        flex: 1;
        @extend %flex-c-c;
        @extend %text-center;
        @extend %p-2;
        @extend %secondary-bg;
        @extend %white;
        &.active {
            font-weight: 700;
            @extend %primary-bg;
        }
        &:not(:last-child) {
            @include separator(60%, y)
        }
    }
    &.secondary-tabs {
        @extend %px-2;
        .tab-name {
            @extend %transparent-bg;
            @include border(2, white, 1, top);
            @include border(2, white, 1, bottom);
        }
        .active {
            @include border(2, accent, 10, bottom);
            @extend %accent;
        }
    }
}
.details-head {
    position: sticky;
    position: -webkit-sticky;
    top: var(--sticky-scores);
    left: 0;
    z-index: var(--z-sticky-tab);
}
.tab-container {
    @extend %secondary-dark-bg;
    @extend %white;
    @extend %py-4;
    &-wrap {
        .tab-container-playbyplay {
            @extend %medium-grey-bg;
            @extend %secondary-dark;
            .tab-title {
                @extend %secondary-light;
            }
        }
    }
}
@include mq(col-md) {
    .details {
        &-container {
            position: relative;
            width: 50%;
            margin-left: auto;
        }
        &-head {
            position: relative;
            z-index: unset;
            top: 0;
        }
    }
    .tab {
        &-container {
            height: calc(var(--window-inner-height) - var(--sticky-scores) - 4rem);
            overflow: auto;
            &-playbyplay {
                position: absolute;
                left: -100%;
                top: 0;
                width: 100%;
                height: calc(var(--window-inner-height) - var(--sticky-scores));
                .tab-title {
                    font-size: 2rem;
                    text-align: left;
                    padding-left: var(--space-6);
                }
            }
            &-lineups {
                .tab-container {
                    height: unset;
                }
            }
        }
        &-item {
            font-size: 1.4rem;
        }
        &-name {
            font-size: 1.4rem;
            height: 4rem;
        }
        &-name.tab-playbyplay {
            display: none;
        }
    }
}