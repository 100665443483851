@use '../config/' as *;

.playbyplay {
    &-list {
        @extend %flex-column;
        @extend %gap-3;
        @extend %p-0-3;
    }

    &-item {
        box-shadow: 0 .2rem .2rem 0 hsl(var(--hsl-black)/0.1);
        @extend %white-bg;
        @extend %p-2;
        @extend %flex;
        @extend %gap-2;
        @extend %quarter-radius;

        .timer {
            @extend %error;
        }

        &.goal {
            padding: var(--space-2) var(--space-2) .1rem var(--space-2);
            background-size: cover;
            background : url("/static-assets/images/cssimages/live-pattern.png?v=#{$image-version}"),
            linear-gradient(126.11deg, var(--secondary-dark) 0%, var(--primary) 189.4%);
            @extend %secondary-dark-bg;
            @extend %white;

            .title-wrap {
                @include border(1, white, 2, bottom);
            }

            .player-data {
                flex-direction: column;
                align-items: flex-start;
            }

            .icon {
                @extend %secondary-dark;
                @extend %white-bg-2;

                &:after {
                    @extend %white-bg;
                }
            }

            .timer {
                @extend %accent;
            }
        }

        &.missed-penalty {
            padding: var(--space-2) var(--space-2) 0.1rem var(--space-2);

            .icon {
                @extend %error-bg-1;

                &::after {
                    @extend %error-bg-7;
                }
            }
        }

        &.red-card {
            padding: var(--space-2) var(--space-2) .1rem var(--space-2);

            .icon::after {
                @extend %error;
            }
        }

        &.yellow-card {
            padding: var(--space-2) var(--space-2) .1rem var(--space-2);

            .icon::after {
                @extend %warning;
            }
        }

        &.substitution {
            padding: var(--space-2) var(--space-2) .1rem var(--space-2);

            .icon {
                isolation: isolate;
                @extend %relative;

                &::after {
                    @extend %subsitution;
                }

                &::before {
                    content: "";
                    top: 10%;
                    left: 10%;
                    @extend %w-80;
                    @extend %h-80;
                    @extend %position-t-l;
                    @extend %circle-radius;
                    @extend %secondary-dark-bg;
                    z-index: var(--z-negative);
                }
            }
        }

        &.external {
            .title-wrap {
                border: 0;
                margin-bottom: 0;
                margin-top: var(--space-2);
            }
        }

        &.instagram {
            iframe {
                min-width: 10rem !important;
            }
        }

        &.second-yellow {
            padding: var(--space-2) var(--space-2) .1rem var(--space-2);

            .icon {
                isolation: isolate;
                @extend %relative;

                &::after {
                    content: "";
                    background-size: 65%;
                    @extend %second-yellow;
                }

                &::before {
                    content: "";
                    top: 10%;
                    left: 10%;
                    @extend %w-80;
                    @extend %h-80;
                    @extend %position-t-l;
                    @extend %circle-radius;
                    @extend %secondary-dark-bg;
                    z-index: var(--z-negative);
                }
            }
        }
    }

    &-head {
        width: 4rem;

        .icon {
            width: 4rem;
            height: 4rem;
            @extend %p-1;
            @extend %flex-c-c;
            @extend %secondary-dark-bg-2;
            @extend %white;
            @extend %font-0;
            @extend %circle-radius;

            &::after {
                content: "";
                @include icon(info);
                @extend %flex-c-c;
                @extend %w-100;
                @extend %h-100;
                @extend %circle-radius;
                @extend %secondary-dark-bg;
            }
        }
    }

    &-body {
        flex: 1;

        .title-wrap {
            @include border(1, secondary-dark, 2, bottom);
            @extend %flex-n-fs;
            @extend %gap-1;
            @extend %pb-2;
            @extend %mb-2;
            @extend %font-14-pb;
        }

        .club-logo {
            width: 2rem;
            height: 2rem;
        }

        .player {
            &-content {
                @extend %flex-n-c;
                @extend %gap-2;
                @extend %px-3;
            }

            &-image {
                width: 5rem;
                // filter: drop-shadow(0px -0.2rem 0.4rem hsl(var(--hsl-black)/0.2))
            }

            &-in {
                @extend %success;
                @extend %font-10-pb;

                &::after {
                    @include icon(long-down-arrow);
                }
            }

            &-name {
                @extend %uppercase;
                @extend %font-11-pb;
            }

            &-assist {
                @extend %font-9-pm;
            }

            &-data {
                @extend %flex-n-c;
                @extend %gap-1;
            }

            &-out {
                @extend %error;
                @extend %font-10-pb;

                &::after {
                    @include icon(long-up-arrow);
                }
            }

            &-item {
                &:not(:last-child) {
                    @include border(2, secondary-dark, 10, bottom);
                    @extend %mb-2;
                }
            }

            &-list {
                width: max-content;
            }
        }
    }
}

@each $type,
$val in $playScaniro {
    .#{$type} {
        .icon {
            &::after {
                @include icon($val, 14)
            }
        }
    }
}

.yellow-card .icon {
    &::after {
        @extend %warning;
    }
}

.red-card .icon {
    &::after {
        @extend %primary;
    }
}
@media screen and (min-width: $small-mobile-max-width) {
    .playbyplay-body .title-wrap .text:nth-last-child(3) {
        white-space: nowrap;
    }
}
@include mq(col-md) {
    .playbyplay {
        &-list {
            padding-inline: var(--space-6);

            .goal {
                position: relative;

                &::after {
                    content: "Goal";
                    text-transform: uppercase;
                    font-size: 3rem;
                    font-weight: 700;
                    opacity: 0.2;
                    color: var(--white);
                    position: absolute;
                    bottom: var(--space-2);
                    right: var(--space-2);
                }

                &.own-goal {
                    &::after {
                        content: "Own Goal";
                    }
                }

                &.goal-penalty {
                    &::after {
                        content: "Goal Penalty";
                    }
                }
            }
        }

        &-head {
            width: 5rem;

            .icon {
                width: 5rem;
                height: 5rem;

                &::after {
                    font-size: 1.6rem;
                }
            }
        }

        &-body {
            .title-wrap {
                font-size: 1.6rem;
            }

            .content-para {
                font-size: 1.3rem;
            }

            .player {
                &-name {
                    text-transform: uppercase;
                    font-size: 1.2rem;
                }

                &-assist {
                    font-size: 1rem;
                }
            }
        }
    }
}