@use '../config/' as *;
.tab-container-item.poll-section {
    @extend %p-5;
    .radio {
        &-box-wrap {
            flex-direction: column;
            @extend %w-100;
            @extend %gap-2;
        }
        &-container {
            @extend %py-3;
        }
    }
    .global-mgs {
        @extend %w-100;
    }
    .vote-now {
        @extend %w-100;
    }
}
.waf-poll-banner {
    .banner-link {
        @extend %d-block;
        @extend %mb-3;
    }
}
@include mq(col-md) {
    .tab-container-item.poll-section {
        .radio {
            &-box-wrap {
                flex-direction: row;
                gap: var(--space-6);
            }
        }
    }
}