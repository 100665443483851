@use '../config/' as *;
.stories-section {
    isolation: isolate;
    .waf-head {
        @extend %d-none;
    }
    .article {
        &-content {
            @extend %d-none;
        }
        &-thumbnail {
            @include border(1, white, 5);
            @extend %p-1;
            @extend %circle-radius;
            @extend %transition;
        }
        &-list {
            @extend %flex-c-c;
            @extend %gap-3;
        }
        &-item {
            flex-shrink: 0;
            width: 20%;
            max-width: 8rem;
            &:hover {
                .article-thumbnail {
                    padding: 0;
                }
            }
            &:not(.visited) {
                .article-thumbnail {
                    border: 0;
                    @extend %relative;
                    &::after {
                        border: .2rem solid transparent;
                        content: "";
                        border-image: linear-gradient(45deg, var(--primary), var(--secondary)) border-box;
                        background: linear-gradient(45deg, var(--primary), var(--secondary)) border-box;
                        -webkit-mask: linear-gradient(var(--white) 0 0) padding-box, linear-gradient(var(--white) 0 0);
                        mask-composite: add, add;
                        -webkit-mask-composite: xor;
                        mask-composite: exclude;
                        position: absolute;
                        inset: 0;
                        z-index: var(--z-negative);
                        @extend %circle-radius;
                    }
                }
            }
        }
    }
    .img-box {
        aspect-ratio: 1/1;
        @extend %circle-radius;
    }
}
.listing-section {
    @include listing-card(rhs, 16.6rem);
    @extend %white;
    @extend %mt-4;
    .waf-listing {
        padding: 0;
    }
    .title {
        opacity: 0.4;
        @extend %flex-fs-n;
        @extend %font-0;
        &::before {
            content: attr(data-title-first);
            @extend %font-22-pr;
        }
        &::after {
            content: attr(data-title-last);
            @extend %font-22-pb;
            @extend %ml-1;
        }
        // &:first-child {
        //     font-weight: 400;
        // }
    }
    .head-wrap {
        justify-content: flex-start;
        @extend %px-3;
        @extend %gap-1;
    }
    .article {
        &-list {
            flex-direction: row;
            flex-wrap: nowrap;
            @include custom-scroll();
            @extend %p-2-3;
            @extend %gap-2;
            @extend %x-auto;
        }
        &-content {
            padding-block: var(--space-1);
            border-radius: 0 0 var(--quarter-radius) var(--quarter-radius);
            @include bg(white, 1);
            @extend %flex-column;
        }
        &-description {
            @extend %d-none;
        }
        &-title {
            margin-bottom: 0;
            @include truncate(3, 12);
            @extend %white;
        }
        &-meta {
            @extend %d-none;
        }
        &-item {
            flex-shrink: 0;
            @extend %w-90;
            &:hover {
                a,
                .article-title,
                .item-type-icon::before {
                    color: var(--white);
                }
            }
        }
    }
    .icon-time-wrapper {
        order: -1;
    }
    .timestamp {
        @extend %d-none;
    }
    .img-box {
        border-radius: var(--half-radius) 0 0 var(--half-radius);
    }
    a {
        @extend %white;
    }
    .swiper-button {
        &-next,
        &-prev {
            @extend %d-none;
        }
    }
}
@include mq(col-md) {
    .stories-section {
        .article-thumbnail {
            padding: 0.75rem;
        }
    }
    .listing-section {
        --swiper-left: 15rem;
        margin-top: var(--space-6);
        position: relative;
        .layout-wrapper {
            display: flex;
            justify-content: center;
            gap: var(--space-2);
            padding-inline: var(--space-8);
        }
        .waf {
            &-head {
                width: 16rem;
                flex-shrink: 0;
            }
            &-body {
                width: calc(100% - 16rem);
            }
        }
        .head-wrap {
            display: block;
            .title {
                line-height: 1.2;
                margin: 0;
                display: flex;
                width: 100%;
                flex-direction: column;
                align-items: flex-end;
                &::after,
                &::before {
                    display: block;
                    font-size: 2.6rem;
                }
            }
        }
        .article {
            &-list {
                --swiper-left: 45rem;
                padding: 0;
                gap: 0;
                overflow: hidden;
                width: max-content;
            }
            &-two {
                --swiper-left: 29rem;
                .waf-body {
                    width: 60%;
                }
                .article-list {
                    @include card-count(2);
                }
            }
            &-one {
                .waf-body {
                    width: 30%;
                }
                .article-list {
                    @include card-count(1);
                }
            }
        }
        .swiper {
            position: unset;
            width: 100%;
            &-button {
                &-next,
                &-prev {
                    display: flex;
                    inset: unset;
                    left: var(--swiper-left);
                    bottom: -0.7rem;
                }
                &-prev {
                    left: calc(var(--swiper-left) - 4rem);
                }
            }
        }
    }
}